import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../core/user';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Typography,
} from '@mui/material';
import { fetchInstances } from '../../services/InstanceServices';
import { red } from '@mui/material/colors';
import { addACL } from '../../services/ACLServices';

const AddAclDialog = ({ open, onClose, onSubmit }) => {
  const { userState } = useContext(UserContext);
  const [formData, setFormData] = useState({
    instance_name: '',
    source_ip: '',
    destination_ip: '',
    port: '',
    protocol: '',
    description: '',
  });
  const [instanceOptions, setInstanceOptions] = useState([]); // Fetch된 인스턴스 목록
  const [selectedInstance, setSelectedInstance] = useState(null); // 선택한 인스턴스의 추가 정보

  useEffect(() => {
    const loadInstances = async () => {
      try {
        const response = await fetchInstances(); // 부모 컴포넌트에서 전달받은 fetchInstances 호출
        if (response.ok) {
          const data = await response.json();
          setInstanceOptions(data); // 가져온 인스턴스 목록 저장
        } else {
          console.error('Failed to fetch instances:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching instances:', error);
      }
    };

    if (open) {
      loadInstances(); // Dialog가 열릴 때 데이터 로드
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // 선택된 instance_name에 맞는 추가 정보를 설정
    if (name === 'instance_name') {
      const instance = instanceOptions.find((inst) => inst.instance_name === value);
      setSelectedInstance(instance || null);
    }
  };

  const handleAdd = async () => {

    if (!userState?.uid || !selectedInstance?.iid) {
      alert('User or Instance information is missing.');
      return;
    }
  
    const requestData = {
      uid: userState.uid,
      iid: selectedInstance.iid,
      source_ip: formData.source_ip,
      destination_ip: formData.destination_ip,
      port: parseInt(formData.port),
      protocol: formData.protocol,
      is_allow: true,
      description: formData.description,
    };
  
    try {
      // 디버깅: 데이터 확인
      console.log('Request Data:', requestData);

      const response = await addACL(userState.uid, selectedInstance.iid, requestData);
  
      if (response.ok) {
        alert('Access Control List is successfully added!');
        onSubmit(); // 부모 컴포넌트로 추가된 데이터 전달
      } else {
        const error = await response.json();
        alert(`Failed to add ACL: ${error.message}`);
      }
    } catch (error) {
      console.error('Error adding ACL:', error);
      alert('An error occurred while adding ACL.');
    } finally {
      // UI 상태 초기화 및 닫기
      setFormData({
        instance_name: '',
        source_ip: '',
        destination_ip: '',
        port: '',
        protocol: '',
      });
      setSelectedInstance(null);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md" // Dialog 최대 너비를 medium으로 설정 (sm, md, lg 등 사용 가능)
      fullWidth // Dialog의 너비를 최대화
      sx={{
        '& .MuiDialog-paper': {
          width: '800px', // 원하는 너비 설정
          maxWidth: 'none', // 최대 너비 제한 해제
        },
      }}
    >
      <DialogTitle>Add Access Control List(ACL)</DialogTitle>
      <Typography
        variant="body2"
        color="error" // 빨간색으로 변경
        sx={{ mt: 1, ml: 3, fontStyle: 'italic' }} // 왼쪽 마진 추가
        >
        ※ 주의사항: ACL에 추가해야만 양방향 통신이 가능합니다.
      </Typography>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <TextField
            select
            label="Instance Name"
            name="instance_name"
            value={formData.instance_name}
            onChange={handleInputChange}
            fullWidth
          >
            {instanceOptions.map((option) => (
              <MenuItem key={option.id} value={option.instance_name}>
                {option.instance_name}
              </MenuItem>
            ))}
          </TextField>

          {selectedInstance && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                <strong>OS:</strong> {selectedInstance.instance_os}
              </Typography>
              <Typography variant="body2">
                <strong>CSP Name:</strong> {selectedInstance.csp_name}
              </Typography>
              <Typography variant="body2">
                <strong>Public IP:</strong> {selectedInstance.public_ip}
              </Typography>
              <Typography variant="body2">
                <strong>Description:</strong> {selectedInstance.description}
              </Typography>
            </Box>
          )}

          <TextField
            label="Source IP"
            name="source_ip"
            value={formData.source_ip}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Destination IP"
            name="destination_ip"
            value={formData.destination_ip}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Port"
            name="port"
            value={formData.port}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            select
            label="Protocol"
            name="protocol"
            value={formData.protocol}
            onChange={handleInputChange}
            fullWidth
          >
            

            <MenuItem value="TCP">TCP</MenuItem>
            <MenuItem value="UDP">UDP</MenuItem>
            <MenuItem value="ICMP">ICMP</MenuItem>
            <MenuItem value="SSH">SSH</MenuItem>
            <MenuItem value="HTTP">HTTP</MenuItem>
            <MenuItem value="HTTPS">HTTPS</MenuItem>
          </TextField>
          <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                fullWidth
            />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAclDialog;
