import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import AddAclDialog from './AddAclDialog'; // Dialog 컴포넌트 가져오기
import { deleteACL } from '../../services/ACLServices'; // ACL 삭제 API 호출 함수 가져오기

import { useContext } from 'react';
import { UserContext } from '../../core/user';

import { fetchACLs } from '../../services/ACLServices';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          padding: '4px 12px',
        },
      },
    },
  },
});

function preventDefault(event) {
  event.preventDefault();
}

export default function ACLTable() {
  const [rows, setRows] = useState([]);
  const { userState } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addACLTrigger, setAddACLTrigger] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // 데이터를 불러오는 함수
  const loadACLs = async () => {
    try {
      const response = await fetchACLs(userState.uid); // fetchACLs API 호출
      if (response.ok) {
        const data = await response.json(); // JSON 데이터 파싱
        setRows(data); // rows 상태 업데이트
        console.log('Fetched ACLs:', data);
      } else {
        console.error('Failed to fetch ACLs:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching ACLs:', error);
    } finally {
      setLoading(false); // 로딩 상태 해제
    }
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleAddAcl = () => {
    setAddACLTrigger((prev) => !prev);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row.aid === selectedRow ? null : row.aid); // 클릭 시 행 선택/해제
  };

  const handleDelete = async () => {
    if (!selectedRow) {
      alert('Please select a row to delete.');
      return;
    }

    try {
      const response = await deleteACL(selectedRow); // ACL 삭제 API 호출
      if (response.ok) {
        alert('ACL successfully deleted.');
        setAddACLTrigger((prev) => !prev); // 테이블 다시 로드
        setSelectedRow(null); // 선택 초기화
      } else {
        alert('Failed to delete ACL.');
      }
    } catch (error) {
      console.error('Error deleting ACL:', error);
      alert('An error occurred while deleting ACL.');
    }
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    loadACLs();
  }, [addACLTrigger]);

  return (
    <React.Fragment>
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2, // 하단 마진 추가
        }}
      >
        {/* Title */}
          <Title>Access Control List</Title>

          {/* 버튼 그룹 */}
          <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
              variant="contained"
              color="primary"
              sx={{ fontWeight: 'bold' }}
              onClick={handleDialogOpen}
            >
              ADD
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ fontWeight: 'bold' }}
              onClick={handleDelete}
            >
              DELETE
            </Button>
          </Box>
        </ThemeProvider>
      </Box>

      <Table sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Account</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Instance Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Source IP</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Destination IP</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Port</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Protocol</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>isAllow</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.aid}
              onClick={() => handleRowClick(row)}
              sx={{
                cursor: 'pointer',
                backgroundColor: row.aid === selectedRow ? 'rgba(0, 0, 255, 0.1)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <TableCell>{row.user.username}</TableCell>
              <TableCell>{row.instance.instance_name}</TableCell>
              <TableCell>{row.source_ip}</TableCell>
              <TableCell>{row.destination_ip}</TableCell>
              <TableCell>{row.port}</TableCell>
              <TableCell>{row.protocol}</TableCell>
              <TableCell>{row.is_allow ? 'Allow' : 'Deny'}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{formatDate(row.updated_at)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <AddAclDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleAddAcl}
      />

    </React.Fragment>
  );
}