import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Paper, Typography, Link } from '@mui/material';
import ACLTable from './AccessControlList';
import { UserContext } from '../../core/user';

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://sec.pusan.ac.kr/sec/index.do">
                PNU S3Lab
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const AccessControlListPage = () => {
    const { userState } = useContext(UserContext);
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                width: '100%',
            }}
        >
            <Container sx={{ mt: 4, mb: 4, width: '100%', maxWidth: 'none', minWidth: '1500px' }}>
                <Grid container spacing={3} sx={{ display: 'flex', width: '100%', minWidth: '1500px' }}>
                    {/* Access Control List, Size is Max */}
                    <Grid item xs={12} sx={{ width: '100%', minWidth: '1500px' }}>
                        <Paper
                            sx={{
                                p: 2,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <ACLTable username={userState.username} />
                        </Paper>
                    </Grid>
                </Grid>
                <Copyright sx={{ pt: 4 }} />
            </Container>
        </Box>
    );
};

export default AccessControlListPage;
