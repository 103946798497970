import AppConfig from '../core/config';
import { fetchRefresh } from './AuthServices';

// Fetch ACLs by uid
const fetchInstances = async () => {
    const url = `${AppConfig.api_base_url}/instance/instances`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

export { fetchInstances };