import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import TableViewIcon from '@mui/icons-material/TableView';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';


export const MainListItems = () => {
  const navigate = useNavigate();

  const handleNavigation = useCallback(
          (path) => {
              navigate(path);
          },
          [navigate],
      );

  return (
    <React.Fragment>
      <ListItemButton onClick={() => handleNavigation('/Project')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => handleNavigation('/ACL')}>
        <ListItemIcon>
          <TableViewIcon />
        </ListItemIcon>
        <ListItemText primary="Access Control List" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports (In Dev)" />
      </ListItemButton>
    </React.Fragment>
  );
};

export const MonitoringListItems = () => {
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (path) => {
        navigate(path);
    },
    [navigate],
  );

  return (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <MonitorHeartIcon />
      </ListItemIcon>
      <ListItemText primary="Resource Viewer" />
    </ListItemButton>
  </React.Fragment>
);
}

export const LoggingListItems = () => {
  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (path) => {
        navigate(path);
    },
    [navigate],
  );

  return (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Instance Logger
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Syscall Logs" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Event Logs" />
    </ListItemButton>
  </React.Fragment>
);
}